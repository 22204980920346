@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "font-ej";
  src: url("./assets/fonts/easyJetRoundedLight.woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "font-ej";
  src: url("./assets/fonts/easyJetRoundedBook.woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "font-ej";
  src: url("./assets/fonts/easyJetRoundedDemi.woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.font-ej {
  font-family: "font-ej";
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

.arrow {
  position: absolute;
  cursor: pointer;
  margin-top: -2rem;
}

.arrow span {
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-bottom: 4px solid white;
  border-right: 4px solid white;
  transform: rotate(45deg);
  margin-top: -4px;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

.menu-icon {
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 4px;

  .menu-icon__cheeckbox {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    z-index: 2;
    -webkit-touch-callout: none;
    position: absolute;
    opacity: 0;
  }
  div {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 26px;
    height: 16px;
  }
  span {
    position: absolute;
    display: block;
    width: 100%;
    height: 5px;
    background-color: var(--bar-bg, #ff5601);
    border-radius: 4px;
    transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

    &:first-of-type {
      top: 0;
    }
    &:last-of-type {
      bottom: 0;
    }
  }
  &.active,
  .menu-icon__cheeckbox:checked + div {
    span {
      &:first-of-type {
        transform: rotate(45deg);
        top: 6px;
      }
      &:last-of-type {
        transform: rotate(-45deg);
        bottom: 5px;
      }
    }
  }
}

mux-player {
  --controls: none;
  --media-object-fit: cover;
  --media-object-position: center;
}

.center-play {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
}

.nowhitespace {
  white-space: nowrap !important;
}

.pad-5 {
  padding: 5px;
}
.ejheadlinefont {
  font-family: "font-ej";
  font-weight: bold;
}
.ejheadlinefontsmall {
  font-family: "font-ej";
  font-weight: bold;
}
.quick5rem {
  height: 80px;
}
.pady-10 {
  margin-bottom: 10px;
}
.font-size3x1 {
  font-size: 1.675rem;
}

.pwordbox {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pwordbox form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pwordbox form input {
  border: 1px solid black;
  padding: 4px;
}
.pwordbox form button {
  border: 1px solid black;
  padding: 20px;
}

.optin {
  border-bottom: 2px solid #ffa96f;
  border-top: 1px solid #ffcfaf;
  padding: 20px 0 20px 55px;
  position: relative;
  line-height: 14px;
  font-size: 14px;
}
.optin:before {
  content: "";
  width: 100%;
  border-top: 1px solid #ffa96f;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  display: block;
}
input[type="checkbox"] {
  display: none;
}
input[type="checkbox" i] {
  background-color: initial;
  cursor: default;
  appearance: auto;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: initial;
  border: initial;
}
.optin label {
  cursor: pointer;
  line-height: 1rem;
}
.optin label:before {
  content: "";
  width: 34px;
  height: 34px;
  font-size: 34px;
  line-height: 1.5rem;
  text-align: center;
  background-color: #f2f2f2;
  font-family: "font-ej";
  color: #ffa96f;
  position: absolute;
  left: 0px;
}
.optin input:checked + label:before {
  content: "X";
}
form {
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  padding: 15px;
  font-family: "font-ej", ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
  font-size: 1.1rem;
  background: white;
  height: 91%;
  overflow: scroll;
}
input,
select {
  width: 100%;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}
input[type="submit"] {
  color: white;
  background-image: linear-gradient(180deg, #f90, #ff4600);
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}
.aligntextleft {
  text-align: left;
}
.thanks {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #414141;
}
.eager {
  font-size: 1.2rem;
  font-weight: 500;
  color: #414141;
}
.orangeborder {
  border: 2px solid #f27d00;
}
.formintro {
  margin-bottom: 20px;
}
.bluelink {
  color: blue !important;
  text-decoration: underline;
}
.margin15pxbot {
  margin-bottom: 15px;
}
.closebtn {
  font-family: "font-ej";
  font-size: 1.8rem;
  height: 30px;
  line-height: 1;
  position: absolute;
  right: 1px;
  top: 1px;
  width: 30px;
  z-index: 300;
  color: #141414;
}
.location-pin {
  background: none;
  color: #fff;
  font-size: 0.9rem;
  position: absolute;
  right: 10px;
  text-align: right;
  top: 10px;
  z-index: 100;
  display: flex;
  align-items: center;
}
.location-pin::before {
  content: "";
  width: 32px;
  display: block;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 12C22.5166 12 21.0666 12.4399 19.8332 13.264C18.5999 14.0881 17.6386 15.2594 17.0709 16.6299C16.5032 18.0003 16.3547 19.5083 16.6441 20.9632C16.9335 22.418 17.6478 23.7544 18.6967 24.8033C19.7456 25.8522 21.082 26.5665 22.5368 26.8559C23.9917 27.1453 25.4997 26.9968 26.8701 26.4291C28.2406 25.8614 29.4119 24.9001 30.236 23.6668C31.0601 22.4334 31.5 20.9834 31.5 19.5C31.5 17.5109 30.7098 15.6032 29.3033 14.1967C27.8968 12.7902 25.9891 12 24 12ZM24 24C23.11 24 22.24 23.7361 21.4999 23.2416C20.7599 22.7471 20.1831 22.0443 19.8425 21.2221C19.5019 20.3998 19.4128 19.495 19.5865 18.6221C19.7601 17.7492 20.1887 16.9474 20.818 16.318C21.4474 15.6887 22.2492 15.2601 23.1221 15.0865C23.995 14.9128 24.8998 15.0019 25.7221 15.3425C26.5443 15.6831 27.2471 16.2599 27.7416 16.9999C28.2361 17.74 28.5 18.61 28.5 19.5C28.5 20.6935 28.0259 21.8381 27.182 22.682C26.3381 23.5259 25.1935 24 24 24ZM24 3C19.6255 3.00496 15.4315 4.74494 12.3382 7.83822C9.24494 10.9315 7.50496 15.1255 7.5 19.5C7.5 25.3875 10.2206 31.6275 15.375 37.5469C17.691 40.2217 20.2977 42.6303 23.1469 44.7281C23.3991 44.9048 23.6996 44.9996 24.0075 44.9996C24.3154 44.9996 24.6159 44.9048 24.8681 44.7281C27.712 42.6294 30.3137 40.2208 32.625 37.5469C37.7719 31.6275 40.5 25.3875 40.5 19.5C40.495 15.1255 38.7551 10.9315 35.6618 7.83822C32.5685 4.74494 28.3745 3.00496 24 3ZM24 41.625C20.9006 39.1875 10.5 30.2344 10.5 19.5C10.5 15.9196 11.9223 12.4858 14.4541 9.95406C16.9858 7.42232 20.4196 6 24 6C27.5804 6 31.0142 7.42232 33.5459 9.95406C36.0777 12.4858 37.5 15.9196 37.5 19.5C37.5 30.2306 27.0994 39.1875 24 41.625Z' fill='%23FF7700'/%3E%3C/svg%3E%0A");
}
.ended-popup {
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  font-family: "font-ej", ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
  font-size: 1.1rem;
  background: white;
  height: 60%;
  overflow: scroll;
  align-items: center;
  text-align: center;
}
.social-links {
  padding: 20px;
  margin: 10px;
  width: 300px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  gap: 7px;
}

a.social-icon img {
  width: 40px;
}
